import { axios, baseURL } from '@axios'
import encryptionService from '@/services/encryption.service'
import { DirectUpload } from '@rails/activestorage'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReceipts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/receipts', { params: queryParams })
          .then(response => (resolve(response)))
          .catch(error => reject(error))
      })
    },
    fetchReceipt(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/receipts/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClerks(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoiceMetas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/invoices/metas', { params: queryParams })
          .then(response => (resolve(response)))
          .catch(error => reject(error))
      })
    },
    fetchPaymentMethods() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/payment_methods')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInsuranceCategories() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/categories')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInsuranceProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/products')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInsurances() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/insurances')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBluecardFinances() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/bluecard_finances')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPickupLocations() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/pickup_locations')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAdditionalFeesInfos() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/additional_fees_infos')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomerTypes() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/customer_types')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadPolicies(ctx, params) {
      return new Promise((resolve, reject) => {
        const urlParams = new URLSearchParams()
        urlParams.append('search_text', params.search_text)
        params.user_ids.forEach(userId => urlParams.append('user_ids[]', userId))
        params.branch_ids.forEach(branchId => urlParams.append('branch_ids[]', branchId))
        params.insurance_ids.forEach(insuranceId => urlParams.append('insurance_ids[]', insuranceId))
        urlParams.append('start_date', params.start_date)
        urlParams.append('end_date', params.end_date)
        urlParams.append('branch_toggle_filter', params.branch_toggle_filter)

        fetch(`${baseURL}/api/v1/receipts/bulk_download?${urlParams}`, {
          method: 'GET',
          headers: JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders'))),
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
    createReceipt(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/receipts', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateReceipt(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/receipts/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidReceipt(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/receipts/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateValue(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/receipts/${params.id}/value`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    printReport(ctx, params) {
      return new Promise((resolve, reject) => {
        const urlParams = new URLSearchParams()
        urlParams.append('search_text', params.search_text)
        params.user_ids.forEach(userId => urlParams.append('user_ids[]', userId))
        params.receipt_ids.forEach(receiptId => urlParams.append('receipt_ids[]', receiptId))
        params.excluded_receipt_ids.forEach(excludedId => urlParams.append('excluded_receipt_ids[]', excludedId))
        params.branch_ids.forEach(branchId => urlParams.append('branch_ids[]', branchId))
        params.insurance_ids.forEach(insuranceId => urlParams.append('insurance_ids[]', insuranceId))
        if (params.start_date) urlParams.append('start_date', params.start_date)
        if (params.end_date) urlParams.append('end_date', params.end_date)
        urlParams.append('sms_sent', params.sms_sent)
        if (params.sms_start_date) urlParams.append('sms_start_date', params.sms_start_date)
        if (params.sms_end_date) urlParams.append('sms_end_date', params.sms_end_date)
        urlParams.append('payment_method_filter', params.payment_method_filter)
        urlParams.append('branch_toggle_filter', params.branch_toggle_filter)
        urlParams.append('mode', params.mode)
        if (params.select_all !== undefined) urlParams.append('select_all', params.select_all)
        if (params.spd_list !== undefined) urlParams.append('spd_list', params.spd_list)

        fetch(`${baseURL}/api/v1/receipts.${params.format}?${urlParams}`, {
          method: 'GET',
          headers: JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders'))),
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
    directUploadPolicy(ctx, file) {
      return new Promise((resolve, reject) => {
        const url = `${baseURL}/direct_uploads?referer=insurance_policy`
        const upload = new DirectUpload(file, url, {
          directUploadWillCreateBlobWithXHR: xhr => {
            const authHeaders = JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders')))
            if (authHeaders) {
              xhr.setRequestHeader('access-token', authHeaders.accessToken)
              xhr.setRequestHeader('token-type', authHeaders.tokenType)
              xhr.setRequestHeader('client', authHeaders.client)
              xhr.setRequestHeader('expiry', authHeaders.expiry)
              xhr.setRequestHeader('uid', authHeaders.uid)
            }
          },
        })

        upload.create((error, blob) => {
          if (error) reject(error)
          else resolve(blob)
        })
      })
    },
    directUploadScreenshot(ctx, file) {
      return new Promise((resolve, reject) => {
        const url = `${baseURL}/direct_uploads?referer=payment_screenshot`
        const upload = new DirectUpload(file, url, {
          directUploadWillCreateBlobWithXHR: xhr => {
            const authHeaders = JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders')))
            if (authHeaders) {
              xhr.setRequestHeader('access-token', authHeaders.accessToken)
              xhr.setRequestHeader('token-type', authHeaders.tokenType)
              xhr.setRequestHeader('client', authHeaders.client)
              xhr.setRequestHeader('expiry', authHeaders.expiry)
              xhr.setRequestHeader('uid', authHeaders.uid)
            }
          },
        })

        upload.create((error, blob) => {
          if (error) reject(error)
          else resolve(blob)
        })
      })
    },
    sendWhatsapp(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/receipts/${params.id}/whatsapp_send`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendSMS(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/receipts/${params.id}/sms_send`, { sms_template: params.sms_template })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
